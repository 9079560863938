
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
// import Home from "../components/Home/Home";
// function Index() {
//   return <Home />;
// }

// export default Index;
import { useRouter } from 'next/router'
import {
  initPlasmicLoader,
  PlasmicRootProvider,
  PlasmicComponent,
  ComponentRenderData,
} from '@plasmicapp/loader-nextjs'
import Head from 'next/head'
import { PLASMIC } from '../plasmic.init'

function Index({ plasmicData }) {
  const router = useRouter()

  return (
    <>
      <Head>
        <title>invidza | Yeni Nesil Online Davetiye</title>
        <meta
          name="description"
          content="Düğün ya da etkinliğin için, LCV ve online davetiyeler ile fark yaratmaya hazır mısın? Davetiye seçenekleri arasından en uygun şablonu seç, özelleştir ve gönder!"
        />
        <link rel="canonical" href={'https://invidza.com'} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@publisher_handle" />
        <meta name="twitter:title" content="invidza | Yeni Nesil Online Davetiye" />
        <meta
          name="twitter:description"
          content="Düğün ya da etkinliğin için, LCV ve online davetiyeler ile fark yaratmaya hazır mısın? Davetiye seçenekleri arasından en uygun şablonu seç, özelleştir ve gönder!"
        />
        <meta name="twitter:creator" content="@author_handle" />
        <meta
          name="twitter:image:src"
          content="https://invidza.com/images/OG/anasayfa_slogan.png"
        />

        <meta name="og:title" content="invidza | Yeni Nesil Online Davetiye" />
        <meta property="og:url" content="https://invidza.com/" />
        <meta property="og:image" content="https://invidza.com/images/OG/anasayfa_slogan.png" />
        <meta
          name="og:description"
          content="Düğün ya da etkinliğin için, LCV ve online davetiyeler ile fark yaratmaya hazır mısın? Davetiye seçenekleri arasından en uygun şablonu seç, özelleştir ve gönder!"
        />
        <meta property="og:site_name" content="invidza" />
      </Head>
      <PlasmicRootProvider
        loader={PLASMIC}
        prefetchedData={plasmicData}
        globalVariants={[{ name: 'Locale', value: router.locale }]}
      >
        <PlasmicComponent component="/-2" />
      </PlasmicRootProvider>
    </>
  )
}

// Statically fetch the data needed to render Plasmic pages or components.
 const _getStaticProps = async () => {
  // You can pass in multiple page paths or component names.
  const plasmicData = await PLASMIC.fetchComponentData('/-2')
  return {
    props: {
      plasmicData,
      // ...
    },
    revalidate: 300,
  }
}

export default Index


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  