import { initPlasmicLoader } from '@plasmicapp/loader-nextjs'
export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: 'eXsmpb7saw3J6GxE9yhKks', // ID of a project you are using
      token:
        'WcRNgUx1svqUd0rbyQONgrgXRHxmXx0JAxr0eGNHzCkW0Fa6CSV0Tyr539uehrLaPtFEPREraicdm3MiaRp4bQ', // API token for that project
    },
  ],
  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: true,
})
